import { LoginPageQueryDocument } from '@generated/graphql';
import { LoadingOverlay } from '@src/components/loading-overlay';
import { LoginPage } from '@src/containers/login-page';
import { useQuery } from '@src/hooks/use-graphql-hooks';
import { getUser } from '@src/utils/cookies';
import { useRouter } from 'next/router';
import { useState } from 'react';

export default function Login() {
  const [loggedIn, setLoggedIn] = useState(false);
  const router = useRouter();

  // Previously we were checking the user cookie and redirecting the user to the index page,
  // but there it does a query in server side and redirect the user to login page, causing a redirect loop.
  const { loading } = useQuery(LoginPageQueryDocument, {
    fetchPolicy: 'network-only',
    onCompleted(data) {
      if (data?.viewer) {
        setLoggedIn(!!getUser());
        router.replace({
          pathname: '/',
          query: router.query,
        });
      } else {
        setLoggedIn(false);
      }
    },
    onError: () => {
      setLoggedIn(false);
    },
  });

  return loading || loggedIn ? <LoadingOverlay /> : <LoginPage />;
}
